.dino {
  width: 150px;
  height: 150px;
  position: absolute;
}

.dino.main {
  left: 100px;
}

.dino > div {
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-size: auto 100%;
  position: relative;
  overflow: hidden;
}

.dino.idle > div > img {
  position: absolute;
  animation: dino-idle 1s steps(10) infinite alternate;
}

@keyframes dino-idle {
  from {
    left: 0;
  }

  to {
    left: -1500px;
  }
}

.dino.running > div > img {
  position: absolute;
  animation: dino-running 0.75s steps(8) infinite;
}

@keyframes dino-running {
  from {
    left: 0;
  }

  to {
    left: -1200px;
  }
}

.dino.jumping > div > img {
  position: absolute;
  animation: dino-jumping 1s steps(15) infinite;
  animation-fill-mode: forwards;
}

@keyframes dino-jumping {
  from {
    left: 0;
  }

  to {
    left: -2251px;
  }
}

.dino.jumping.flipping > div {
  animation: dino-flip 1.8s forwards 1;
  animation-delay: 0.1s;
}

@keyframes dino-flip {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
