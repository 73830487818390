.dino-family-wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
}

.dino-parent-wrapper {
  transform: scaleX(-1);
  height: 100%;
  width: 150px;
}

.dino-child-wrapper {
  transform: scaleX(-1) scale(0.6) translateX(360px);
  transform-origin: bottom;
  height: 100%;
  width: 150px;
}

.transferred-gems {
  position: absolute;
  bottom: 0;
  height: 60px;
}

.transferred-gems img {
  position: absolute;
  animation: transfer-gem 0.75s linear 1 forwards;
  transform: scale(0.5) translateX(-250px);
  opacity: 0;
}

@keyframes transfer-gem {
  from {
    transform: scale(0.5) translateX(-250px) rotate(0);
    opacity: 0;
    bottom: 10px;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  to {
    transform: scale(0.5) translateX(-70px) rotate(180deg);
    bottom: -5px;
    opacity: 0;
  }
}
