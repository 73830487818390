.gem-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.gem-wrapper.taken {
  animation: take-gem 0.5s 1 forwards;
}


.gem-default {
  animation: wait-gem 3s infinite;
  animation-timing-function: linear;
}

@keyframes take-gem {
  from {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }

  to {
    transform: scale(0.6) rotate(-360deg);
    opacity: 0;
  }
}

@keyframes wait-gem {
  from {
    transform:  rotate(0deg);
  }

  to {
    transform:  rotate(-360deg);
  }
}

