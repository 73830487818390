.wrapper{
  background: url('../assets/welcome-background.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.container {
  width: 80%;
  max-width: 500px;
  background: #ffb6aa;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
  padding:50px;
  text-align: center;
  border-radius: 30px;
  box-shadow: 5px 4px #fbdbd4;
  border: 2px solid #000;
}

.form-input{
  width: 100%;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  padding:5px;
  outline: none;
  border: 2px solid #000;
  background: #ffede9;
  color: #000;
}

.form-item{
  width: 100%;
  margin-bottom: 10px;
}
.logo{
  width: 80%;
  max-width: 350px;
  margin-bottom: 30px;
}

.button{
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-radius: 10px;
  padding:5px;
  outline: none;
  border: 2px solid #000;
  background: #ffede9;
  color: #000;
}

.button:hover{
  background: #fff5f2;
  box-shadow: 5px 4px #ca7b69;
}

p{
  text-align: left;
  margin-bottom: 10px;
}