.game-canvas {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 200px;
  overflow: hidden;
}

.game-canvas > div {
  position: relative;
}
